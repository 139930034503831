import { ModalForm, ProFormText, ProFormTextArea, ProFormDatePicker, ProFormSwitch, ProFormSelect, ProFormDependency } from '@ant-design/pro-components';
import { Button, Image } from 'antd';
import { useEffect, useRef, useState } from 'react';
import {
    PlusOutlined,
    EditOutlined
} from '@ant-design/icons';

const typeOptions = [
    {
        label: "奖项",
        value: "1",
    },
    {
        label: "合作",
        value: "2",
    },
    {
        label: "进展",
        value: "3",
    },
    {
        label: "活动",
        value: "4",
    },
];

export default function EditNew({
    data, onFinish
}) {
    const formRef = useRef();
    const [modalVisible, setModalVisible] = useState(false);
    useEffect(() => {
        if (!data) return;
        formRef?.current?.setFieldsValue(data);
    }, [data])
    return (<ModalForm
        title={data ? '修改' : '新增'}
        formRef={formRef}
        open={modalVisible}
        trigger={
            <Button
                type="primary"
                icon={data ? <EditOutlined /> : <PlusOutlined />}
                onClick={() => {
                    setModalVisible(true);
                }}
            >
                {data ? '修改' : '新增'}
            </Button>
        }
        onOpenChange={(visible) => {
            setModalVisible(visible)
            formRef?.current?.setFieldsValue(data)

        }}
        onFinish={async (values) => {
            if (onFinish) {
                const { date } = values
                const res = await onFinish({
                    ...values,
                    time: new Date(date).getTime()
                })
                if (res) {
                    formRef.current.resetFields()
                }
                return res
            } else {
                return true
            }
        }}
    >
        <ProFormText
            width="md"
            name="titleZh"
            label="中文标题"
            placeholder="中文标题"
        />
        <ProFormTextArea
            width="md"
            name="descZh"
            label="中文简介"
            placeholder="中文简介"
        />
        <ProFormText
            width="md"
            name="urlZh"
            label="中文链接"
            placeholder="中文链接"
        />

        <ProFormText
            width="md"
            name="titleEn"
            label="英文标题"
            placeholder="英文标题"
        />
        <ProFormTextArea
            width="md"
            name="descEn"
            label="英文简介"
            placeholder="英文简介"
        />
        <ProFormText
            width="md"
            name="urlEn"
            label="英文链接"
            placeholder="英文链接"
        />
        <ProFormText
            width="md"
            name="cover"
            label="封面地址"
            placeholder="封面地址"
        />
        <ProFormDependency name={['cover']}>
            {({ cover }) => {
                return <div> <Image width={300} src={cover} /></div>
            }}
        </ProFormDependency>
        <ProFormDatePicker
            width="md"
            name="date"
            label="发布日期"
            placeholder="发布日期"
            rules={[{ required: true }]}
        />
        <ProFormSelect
            width="md"
            name="tags"
            label="新闻类型"
            mode='tags'
            options={typeOptions}
        />
        <ProFormSwitch
            width="md"
            name="selected"
            label="设为精选"
        />
        <ProFormSwitch
            width="md"
            name="handline"
            label="设为头条"
        />
    </ModalForm>
    )
};