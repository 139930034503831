import cloudbase from "@cloudbase/js-sdk";

const app = cloudbase.init({
    env: "backstage-management-4buc5fa1ddc"
});

const auth = app.auth({
    persistence: "local" //用户显式退出或更改密码之前的30天一直有效
});

const tcb = {
    app,
    auth
}

export default tcb