import { message } from "antd";
import axios from "axios";
import tcb from "./tcb"

export default function ajax(config) {
    const headers = tcb.auth.getAuthHeader()
    return axios({
        // baseURL: "https://web-9gcgcvdd832dc2c4-1316665472.ap-shanghai.app.tcloudbase.com",
        baseURL: "https://api.cellx.co",
        // baseURL: "https://backstage-management-4buc5fa1ddc-1319346437.ap-shanghai.app.tcloudbase.com/",
        ...config,
        headers: {
            ...config.headers,
            ...headers
        },
    }).then(res => {
        const { data, status, statusText } = res
        if (status === 200) {
            const { code } = data
            if (code) {
                // if (tcb.auth) {
                //     tcb.auth.signOut()
                //     tcb.app = null
                //     tcb.auth = null
                //     window.location.reload();
                // }
                throw Error(data.message)
            } else {
                return data
            }
        } else {
            throw Error(statusText)
        }
    }).catch(error => {
        // if (error.code === "ERR_BAD_REQUEST") {
        //     if (tcb.auth) {
        //         tcb.auth.signOut()
        //         tcb.app = null
        //         tcb.auth = null
        //         window.localStorage.clear()
        //         window.location.reload();
        //     }
        // }
        message.error("数据加载失败," + error.message)
        return {}
    })
}