import {
    ProTable,
} from '@ant-design/pro-components';
import {
    DeleteOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import ajax from '../../query';
import { useRef, useState } from 'react';

async function getNew({
    page = 1,
    size = 20
} = {}) {
    return await ajax({
        url: 'message/list',
        method: "GET",
        data: {
            page,
            size
        }
    });
}

async function delNew({ id }) {
    const { code } = await ajax({
        url: 'message/del',
        method: "POST",
        data: {
            id
        }
    });

    return code === 0
}

const allLabel = {
    "how": "如何帮助",
    "howSelect": [
        "产品问题",
        "反馈",
        "媒体或活动",
        "合作伙伴",
        "其他"
    ],
    "role": "角色",
    "roleSelect": [
        "消费者",
        "学生",
        "餐饮服务经营者",
        "零售商",
        "商业或组织",
        "媒体或自媒体人",
        "其他"
    ],
    "f_name": "姓氏",
    "l_name": "名字",
    "email": "邮箱",
    "message": "留言",
}

const labels = {
    "help": {
        title: allLabel.how,
        fixed: "left",
        width: 200,
        render: (v) => {
            const val = allLabel.howSelect[v] || ''
            return <div title={val}>{val}</div>
        }
    },
    "msg": {
        title: allLabel.message,
        width: 400,
        render: (v) => <div title={v}>{v}</div>,
    },
    "f_name": allLabel.f_name,
    "l_name": allLabel.l_name,
    "role": {
        title: allLabel.role,
        render: (v) => {
            const val = allLabel.roleSelect[v] || ''
            return <div title={val}>{val}</div>
        }
    },
    "email": allLabel.email,
    "createTime": {
        title: "留言时间",
        width: 200,
        render: (v) => {
            const date = new Date(v);
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            month = month > 9 ? month : `0${month}`
            let day = date.getDate();
            let hours = date.getHours();
            hours = hours > 9 ? hours : `0${hours}`
            let minutes = date.getMinutes();
            minutes = minutes > 9 ? minutes : `0${minutes}`
            let seconds = date.getSeconds();
            seconds = seconds > 9 ? seconds : `0${seconds}`
            day = day > 9 ? day : `0${day}`
            const dateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
            return <div title={dateString}>{dateString}</div>
        }
    }
}


function Render(value) {
    return <div style={{
        maxWidth: 200,
        overflow: 'hidden',
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    }}>{value}</div>
}

const columns = Object.entries(labels).map(([k, v]) => {
    if (typeof v === "object") {
        return {
            dataIndex: k,
            key: k,
            width: 100,
            render: v.render || Render,
            ...v
        }
    }

    return {
        title: v,
        dataIndex: k,
        key: k,
        render: Render
    }
})


function DeleteBtn({ id, onDeleted }) {
    const [loading, setLoading] = useState(false)
    const handleClick = async function () {
        setLoading(true)
        const res = await delNew({ id })
        setLoading(false);
        if (res) {
            onDeleted && onDeleted()
        }
    }
    return <Button loading={loading} icon={<DeleteOutlined />} danger onClick={handleClick}>删除</Button>
}

export default function Page() {
    const $action = useRef()

    return <ProTable
        actionRef={$action}
        rowKey="_id"
        columns={[
            ...columns,
            {
                title: '操作',
                key: 'option',
                valueType: 'option',
                fixed: "right",
                render: (dom, data, index, action) => {
                    const { _id: id } = data
                    return <DeleteBtn id={id} onDeleted={() => {
                        action.reload()
                    }} />
                },
            },
        ]}
        scroll={{
            x: 800,
            y: 700
        }}
        request={async (params, sorter, filter) => {
            const { current: page, pageSize: size } = params
            const res = await getNew({
                page, size
            });
            const { code, data, total } = res;
            if (code) {
                return {
                    data: [],
                    success: false,
                };
            } else {
                return {
                    data,
                    total,
                    success: true,
                };
            }
        }}
        search={false}
    />

}