import { useEffect, useCallback } from "react";
import cloudbase from "@cloudbase/js-sdk";
import { useNavigate, useResolvedPath } from "react-router-dom";
import tcb from "../tcb"
import { message } from "antd";

const emailSuffix = process.env.REACT_APP_emailSuffix
export default function useSignin() {
    const navigate = useNavigate();
    const { pathname } = useResolvedPath();

    const getLoginState = useCallback(
        () => {
            if (tcb.auth) {
                const loginState = tcb.auth.hasLoginState();
                if (!loginState) {
                    navigate("/login");
                } else {
                    if (pathname === "/login") {
                        navigate("/");
                    }
                }
            } else {
                navigate("/login");
            }

        },
        [navigate, pathname]
    )



    const handleSign = useCallback(({ autoLogin, email, password }, loginType) => {
        let hasChange = true;
        email += emailSuffix
        if (tcb.auth) {
            const _autoLogin = tcb.auth._config.persistence === "local"
            hasChange = _autoLogin === autoLogin
        }
        if (hasChange) {
            tcb.app = cloudbase.init({
                env: "web-9gcgcvdd832dc2c4"
            });
            tcb.auth = tcb.app.auth({
                persistence: "local" //用户显式退出或更改密码之前的30天一直有效
            });
            tcb.auth.onLoginTypeChanged(getLoginState);
        }

        if (loginType === "signUp") {
            tcb.auth.signUpWithEmailAndPassword(email, password).then(() => {
                message.success("发送验证邮件成功")
                // 发送验证邮件成功
            }).catch(error => {
                message.error(error.message);
                // console.log(error);
            })
        } else {
            tcb.auth.signInWithEmailAndPassword(email, password).then((loginState) => {
                // 用户名密码登录成功
                message.success("用户名密码登录成功")
                navigate("/");
            }).catch(error => {
                message.error("邮箱地址与密码是否匹配");
            })
        }


    }, [getLoginState, navigate])


    const handleSignOut = useCallback(() => {
        if (tcb.auth) {
            tcb.auth.signOut()
            tcb.app = null
            tcb.auth = null
            navigate("/login");
        }
    }, [navigate])

    useEffect(() => {
        getLoginState()
    }, [getLoginState])

    return {
        handleSign,
        handleSignOut
    }

}