import React from 'react';
import {
    LockOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    LoginFormPage,
    ProConfigProvider,
    ProFormCheckbox,
    ProFormText,
} from '@ant-design/pro-components';
import { Tabs, theme } from 'antd';
import { useState } from 'react';
import { useSignin } from "../../hooks"

const emailSuffix = process.env.REACT_APP_emailSuffix
const Page = () => {
    const [loginType, setLoginType] = useState('signIn');
    const { handleSign } = useSignin()
    const { token } = theme.useToken();
    return (
        <div
            style={{
                backgroundColor: 'white',
                height: '100vh',
            }}
        >
            <LoginFormPage
                backgroundImageUrl="https://mdn.alipayobjects.com/huamei_gcee1x/afts/img/A*y0ZTS6WLwvgAAAAAAAAAAAAADml6AQ/fmt.webp"
                backgroundVideoUrl="https://gw.alipayobjects.com/v/huamei_gcee1x/afts/video/jXRBRK_VAwoAAAAAAAAAAAAAK4eUAQBr"
                title="CellX"
                containerStyle={{
                    backgroundColor: 'rgba(0, 0, 0,0.65)',
                    backdropFilter: 'blur(4px)',
                }}
                subTitle="cellx官网后台"
                submitter={{
                    searchConfig: {
                        submitText: (loginType === "signIn" ? '登录' : '注册'),
                    },
                }}
                onFinish={(options) => {
                    handleSign(options, loginType)
                }}
            >
                <Tabs
                    centered
                    activeKey={loginType}
                    onChange={(v) => setLoginType(v)}
                >
                    <Tabs.TabPane key={'signIn'} tab={'登录'} />
                    <Tabs.TabPane key={'signUp'} tab={'注册'} />
                </Tabs>
                <ProFormText
                    name="email"
                    fieldProps={{
                        size: 'large',
                        prefix: (
                            <UserOutlined
                                style={{
                                    color: token.colorText,
                                }}
                                className={'prefixIcon'}
                            />
                        ),
                        suffix: emailSuffix
                    }}
                    placeholder={'用户名'}
                    rules={[
                        {
                            required: true,
                            message: '请输入邮箱',
                        },
                    ]}
                />
                <ProFormText.Password
                    name="password"
                    fieldProps={{
                        size: 'large',
                        prefix: (
                            <LockOutlined
                                style={{
                                    color: token.colorText,
                                }}
                                className={'prefixIcon'}
                            />
                        ),
                    }}
                    placeholder={'密码'}
                    rules={[
                        {
                            required: true,
                            message: '请输入密码！',
                        },
                    ]}
                />

                {
                    //     <div
                    //     style={{
                    //         marginBlockEnd: 24,
                    //     }}
                    // >
                    //     <ProFormCheckbox noStyle name="autoLogin">
                    //         自动登录
                    //     </ProFormCheckbox>
                    // </div>
                }
            </LoginFormPage>
        </div>
    );
};

export default function Login() {
    return (
        <ProConfigProvider dark>
            <Page />
        </ProConfigProvider>
    );
};
