import { useRef } from "react"
import { Avatar, message } from "antd"
import { ModalForm, ProFormText, } from '@ant-design/pro-components';
import tcb from "../../tcb"

export default function ResetPasswod({ children }) {
    const formRef = useRef();
    return <ModalForm
        formRef={formRef}
        title="修改密码"
        trigger={<Avatar style={{
            cursor: 'pointer'
        }} icon={children} />}

        onFinish={async (values) => {
            const { oldPassWord, newPassWord } = values
            const user = tcb.auth.currentUser;
            return user.updatePassword(newPassWord, oldPassWord).then(() => {
                message.success("更新密码成功")
                return true
            }).catch(error => {
                message.error(error.message)
                return false
            })

        }}
    >
        <ProFormText.Password
            width="md"
            name="oldPassWord"
            label="旧密码"
            placeholder="旧密码"
            rules={[{ required: true }]}
        />
        <ProFormText.Password
            width="md"
            name="newPassWord"
            label="新密码"
            placeholder="新密码"
            rules={[{ required: true }]}
        />
        <ProFormText.Password
            width="md"
            name="checkPassWord"
            label="确认密码"
            placeholder="确认新密码"
            rules={[{ required: true }, {
                validator: async (rule, value) => {
                    const newPassWord = formRef.current.getFieldValue("newPassWord");
                    if (newPassWord !== value) {
                        throw Error("确认密码和新密码不一致")
                    }
                }
            }]}
        />
    </ModalForm>

}