import {
    UnorderedListOutlined,
    ToolOutlined,
    MessageOutlined,
} from '@ant-design/icons';

const routes = [
    {
        path: '/news',
        name: '新闻',
        icon: <UnorderedListOutlined />,
    },
    {
        path: '/message',
        name: '留言',
        icon: <MessageOutlined />,
    },
    {
        path: '/deploy',
        name: '发布',
        icon: <ToolOutlined />,
    }
]

export default routes