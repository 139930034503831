import { PageContainer, ProCard } from '@ant-design/pro-components';
import { Button, Descriptions, message } from 'antd';
import { SyncOutlined, CheckOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import ajax from '../../query';
import tcb from "../../tcb"

const steps = ["init",
    "clean",
    "install",
    "build",
    "upload",
    "done"]

const stepTitle = {
    init: "初始化",
    clean: "清理",
    install: "安装",
    build: "构建",
    upload: "上传",
    done: "完成",
}

function StepCard(step, status, hasDone) {
    const { progress, total, startTime } = status || {}
    const hasRunning = status && !hasDone;
    return <ProCard step={step} colSpan={12} style={{
        border: `2px solid ${hasDone ? '#4CAF50' : '#d9d9d9'}`,
    }} >
        <p >{stepTitle[step]} {hasDone ? <CheckOutlined /> : ''} {hasRunning ? <SyncOutlined spin /> : ''} {total ? <span> - 进度： {progress}/{total}</span> : ''}</p>
        <p>开始时间： {startTime}</p>
    </ProCard>
}


export default function Deploy() {
    const [state, setState] = useState({
        status: {},
        updateIng: false,
    });
    const user = tcb?.auth?.currentUser
    const { email } = user || {}
    const account = email ? email.split("@")[0] : "";
    const $updateTimeID = useRef();
    const $updateState = useRef();

    const { updateIng, status } = state

    const isRunning = useMemo(() => status.status === "running", [status]);
    const isCanceling = useMemo(() => status.status === "canceling", [status]);
    const isCanceled = useMemo(() => status.status === "canceled", [status]);
    const isDone = useMemo(() => status.status === "done", [status]);
    const isInit = useMemo(() => !status.status, [status]);

    const updateState = useCallback(async (force) => {
        if (!force) {
            if ((!isRunning && !isCanceling) || updateIng) return;
        }
        setState((state) => ({ ...state, updateIng: true }));
        const {
            code,
            message: msg,
            result
        } = await ajax({
            url: 'deploy',
            method: "POST",
            data: {
                action: 'readTaskStatus'
            }
        });
        if (code === 0) {
            setState((state) => ({ ...state, status: result, updateIng: false }));
        } else {
            message.error("更新失败", msg)
        }
    }, [isCanceling, isRunning, updateIng])

    $updateState.current = updateState;

    const handleDeploy = useCallback(async () => {
        const { code } = await ajax({
            url: 'deploy',
            method: "POST",
            data: {
                creater: account,
                action: 'deploy'
            },
        });
        if (code === 0) {
            message.success("开始部署")
        } else {
            message.error("部署失败")
        }
        updateState(true)
    }, [account, updateState])

    const handleCancel = useCallback(async () => {
        const { code } = await ajax({
            url: 'deploy',
            method: "POST",
            data: {
                action: 'cancelTaskStatus'
            },
        });

        if (code === 0) {
            message.success("任务取消中")
        } else {
            message.error("任务取消失败")
        }

    }, [])

    useEffect(() => {
        $updateState.current && $updateState.current(true)
        $updateTimeID.current = setInterval(() => {
            $updateState.current && $updateState.current()
        }, 5000)
        return () => {
            clearInterval($updateTimeID.current)
        }
    }, [])

    return (<div
        style={{
            background: '#F5F7FA',
        }}>
        <PageContainer
            ghost
            header={{
                title: '官网更新',
                breadcrumb: {},
            }}
            content={
                <Descriptions column={2} style={{ marginBlockEnd: -16 }}>
                    <Descriptions.Item label="操作人">{status.creater}</Descriptions.Item>
                    <Descriptions.Item label="部署状态">
                        {isInit ? '未开始' : ''}
                        {isRunning ? '运行中' : ''}
                        {isCanceling ? '取消中' : ''}
                        {isCanceled ? '已取消' : ''}
                        {isDone ? '已完成' : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="开始时间">{status.startTime}</Descriptions.Item>
                    <Descriptions.Item label="操作">
                        {isRunning ? <Button size='small' danger onClick={handleCancel} >取消</Button> : ''}
                        {(isCanceled || isDone || isInit) ? <Button size='small' onClick={handleDeploy} >部署</Button> : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="结束时间">{status.endTime}</Descriptions.Item>
                </Descriptions>
            }
        >

            <ProCard direction="column" gutter={[0, 16]} ghost >
                <ProCard gutter={16} ghost >
                    <ProCard >
                        执行流程：<Button size='small' loading={updateIng} onClick={updateState} icon={<SyncOutlined spin={updateIng} />} >刷新{updateIng ? '中' : ''}</Button>
                    </ProCard>
                </ProCard>
                <ProCard gutter={[16, 16]} wrap ghost>
                    {
                        steps.map((step, index) => {
                            const nextStep = steps[index + 1];
                            const statusSteps = (status && status.steps) || {};
                            const hasDone = isDone || !!statusSteps[nextStep];
                            // const hasErr

                            return StepCard(step, status && status.steps && status.steps[step], hasDone)
                        })
                    }
                </ProCard>

            </ProCard>

        </PageContainer>
    </div >)
}