import {
    LogoutOutlined,
} from '@ant-design/icons';
import {
    ProLayout,
} from '@ant-design/pro-components';
import {
    Button,
} from 'antd';
import React from 'react';
import routes from "../../routes"
import { Outlet, Link, useResolvedPath } from "react-router-dom";
import { useSignin } from "../../hooks"
import tcb from "../../tcb"
import ResetPasswod from "./ResetPassword"

export default function Page() {
    const { handleSignOut } = useSignin();
    const user = tcb?.auth?.currentUser
    const { email } = user || {}
    const account = email ? email.split("@")[0] : "";
    const { pathname } = useResolvedPath();
    return (
        <ProLayout
            title="CellX"
            logo={null}
            avatarProps={{
                render: (...a) => {
                    return <ResetPasswod>{account}</ResetPasswod>
                }
            }}
            actionsRender={() => [
                <Button type="text" icon={<LogoutOutlined />} onClick={handleSignOut} >注销</Button>
            ]}
            {
            ...{
                route: {
                    path: '/',
                    routes: routes
                },
                location: {
                    pathname: pathname,
                },
            }
            }
            menuItemRender={(item, dom) => {
                return <Link to={item.path}>
                    {dom}
                </Link>
            }}
        >
            <Outlet />
        </ProLayout>

    );
};