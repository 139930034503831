import {
    ProTable,
} from '@ant-design/pro-components';
import {
    DeleteOutlined
} from '@ant-design/icons';
import { Button, message, Image } from 'antd';
import ajax from '../../query';
import EditNew from "./EditNew"
import { useRef, useState } from 'react';
import { Link } from "react-router-dom"

async function getNew({
    page = 1,
    size = 20
} = {}) {
    return await ajax({
        url: 'new/get',
        method: "POST",
        data: {
            page,
            size
        }
    });
}

async function addNew(valuses) {
    const { code } = await ajax({
        url: 'new/add',
        method: "POST",
        data: valuses
    });
    if (code === 0) {
        message.success("添加成功")
        return true
    }
    return false
}

async function editNew(data) {
    await ajax({
        url: 'new/edit',
        method: "POST",
        data
    });
}
async function delNew({ id }) {
    const { code } = await ajax({
        url: 'new/del',
        method: "POST",
        data: {
            id
        }
    });

    return code === 0
}



const labels = {
    "titleZh": {
        title: "中文标题",
        fixed: "left",
        width: 300,
        render: (v) => <div title={v}>{v}</div>
    },
    "descZh": "中文描述",
    "urlZh": {
        title: "中文链接",
        render(value, item) {
            return item.urlZh && Render(<Link target='_blank' to={value} >{value}</Link>)
        }
    },
    "titleEn": {
        title: "英文标题",
        width: 300,
        render: (v) => <div title={v}>{v}</div>
    },
    "descEn": "英文描述",
    "urlEn": {
        title: "英文链接",
        render(value, item) {
            return item.urlEn && Render(<Link target='_blank' to={value} >{value}</Link>)
        }
    },
    "selected": {
        title: "精选",
        render(value, { selected }) {
            return Render(selected ? '是' : '-')
        }
    },
    "handline": {
        title: "头条",
        render(value, { handline }) {
            return Render(handline ? '是' : '-')
        }
    },
    "cover": {
        title: "新闻封面",
        width: 140,
        render: (v, item) => {
            return item.cover ? <div style={{
                width: 120,
                height: 80,
                overflow: "hidden"
            }} ><Image
                    width={120}
                    src={v}
                /></div> : v
        }
    },
    "date": "发布日期"
}


function Render(value) {
    return <div style={{
        maxWidth: 200,
        overflow: 'hidden',
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    }}>{value}</div>
}

const columns = Object.entries(labels).map(([k, v]) => {
    if (typeof v === "object") {
        return {
            dataIndex: k,
            key: k,
            width: 100,
            render: v.render || Render,
            ...v
        }
    }

    return {
        title: v,
        dataIndex: k,
        key: k,
        width: 300,
        render: Render
    }
})


function DeleteBtn({ id, onDeleted }) {
    const [loading, setLoading] = useState(false)
    const handleClick = async function () {
        setLoading(true)
        const res = await delNew({ id })
        setLoading(false);
        if (res) {
            onDeleted && onDeleted()
        }
    }
    return <Button loading={loading} icon={<DeleteOutlined />} danger onClick={handleClick}>删除</Button>
}


export default function Page() {
    const $action = useRef()

    return <ProTable
        actionRef={$action}
        rowKey="_id"
        columns={[
            ...columns,
            {
                title: '操作',
                key: 'option',
                width: 300,
                valueType: 'option',
                fixed: "right",
                render: (dom, data, index, action) => {
                    const { _id: id } = data
                    return [
                        <EditNew data={data} id={id} onFinish={async (valuses) => {
                            await editNew({
                                ...valuses,
                                id
                            })
                            try {
                                $action.current.reload();
                            } catch (error) {

                            }
                            return true
                        }} />,
                        <DeleteBtn id={id} onDeleted={() => {
                            action.reload()
                        }} />
                    ]
                },
            },
        ]}
        scroll={{
            x: 800,
            y: 700
        }}
        request={async (params, sorter, filter) => {
            const { current: page, pageSize: size } = params
            const res = await getNew({
                page, size
            });
            const { code, data, total } = res;
            if (code) {
                return {
                    data: [],
                    success: false,
                };
            } else {
                return {
                    data,
                    total,
                    success: true,
                };
            }
        }}
        toolbar={{
            actions: [
                <EditNew onFinish={async (valuses) => {
                    await addNew(valuses)
                    try {
                        $action.current.reload();
                    } catch (error) {

                    }
                    return true
                }}
                />
            ],
        }}
        search={false}
    />

}