import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import { Login, Home, News, Deploy, Message } from "./pages"
import React from "react";
function App() {
  return (
    <HashRouter >
      <Routes>
        <Route
          path="/"
          element={
            <Home />
          }
        >
          <Route path="/news" element={<News />} />
          <Route path="/deploy" element={<Deploy />} />
          <Route path="/message" element={<Message />} />
          <Route index element={<Navigate to="/news" replace={true} />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </HashRouter>
  );
}

export default App;